<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#0F0"/>
    <div v-show="items.length">
      <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th @click="changesort(0)">{{$t('message.msto_th0')}}<b-icon @click="doupdown(0)" :icon="sort==0?(updown==1?'sort-down':'sort-down-alt'):'chevron-bar-contract'" :variant="sort==0?'danger':'primary'"></b-icon></b-th>
          <b-th @click="changesort(1)">{{$t('message.msto_th1')}}<b-icon @click="doupdown(1)" :icon="sort==1?(updown==1?'sort-down':'sort-down-alt'):'chevron-bar-contract'" :variant="sort==1?'danger':'primary'"></b-icon></b-th>
          <b-th>{{$t('message.msto_th2')}}</b-th>
          <b-th>{{$t('message.msto_th3')}}</b-th>
          <b-th>{{$t('message.msto_th4')}}</b-th>
          <b-th>{{$t('message.msto_th5')}}</b-th>
          <b-th>{{$t('message.msto_th6')}}</b-th>
          <b-th>{{$t('message.msto_th7')}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(item, index) in items" :key="index">
        <b-tr>
          <b-td v-if="workingid==index">
              <b-input-group size="sm">
                <b-form-input v-model="item.chargerid"></b-form-input>
                <b-input-group-append>
                  <b-button variant="info" @click="resetchargerid(item)">{{$t('message.btn_save')}}</b-button>
                </b-input-group-append>
              </b-input-group>
          </b-td>
          <b-td v-else>
            <b-button size="sm" variant="outline-info" @click="gotodevice(item)">{{item.chargerid}}</b-button>
            <b-button size="sm" variant="link" @click="setme(index)"><b-icon variant="info" icon="gear"></b-icon></b-button>
          </b-td>
          <b-td :class="item.connected==1?'text-success':'text-secondary'" v-b-tooltip.hover :title="item.cellinfo">{{item.act}}</b-td>
          <b-td>
            <b-button size="sm" variant="default" @click="docmd(8,item)">{{item.pon}}</b-button>
          </b-td>
          <b-td>
            <b-button-group size="sm">
                  <b-button :variant="item.connected==0?'outline-secondary':'outline-success'" @click="docmd(3,item)" :disabled="item.connected==0">
                  <span>{{item.ver}}</span>
                  </b-button>
                  <b-button :variant="item.connected==0?'outline-secondary':'outline-success'" @click="docmd(2,item)" :disabled="item.connected==0">BEP</b-button>
                  <b-button :variant="item.connected==0?'outline-secondary':'outline-success'" @click="docmd(1,item)" :disabled="item.connected==0">RBT</b-button>
                  <b-button variant="outline-success" @click="storedelete(item)"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
            </b-button-group>
          </b-td>
          <b-td v-if="workerid!=index" @click="setwid(index)"><span v-if="cbktype>0">{{item.pow0}}/</span>{{item.pow1}}</b-td>
          <b-td v-if="workerid==index">
                <b-input-group size="sm">
                <b-form-input v-model="item.pow0" class="" style="width:2.4em;" v-if="cbktype>0"></b-form-input>
                <b-form-input v-model="item.pow1" class="" style="width:2.4em;"></b-form-input>
                <b-input-group-append>
                  <b-button variant="info" @click="savepow(item)">Save</b-button>
                </b-input-group-append>
              </b-input-group>
          </b-td>
          <b-td>{{item.netstr}}</b-td>
          <b-td>{{item.ports}}</b-td>
          <b-td v-b-tooltip.hover :title="item.swt">{{resetflags[item.rsf]}}</b-td>
        </b-tr>
      </b-tbody>
      </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0">
            <b-icon icon="x-diamond"></b-icon> NoStores
          </h4>
        </template>
        <b-card-text>
          <p>
            New Devices Initial step:<br />
            1) Check and keep STOP is UP;<br/>
            2) Press DOWN "SET" button on Device board and power on;<br />
            3) Press DOWN STOP button and release after a few seconds;<br />
            4) Device has been reset to factory model;<br />
            5) Reboot device for initial;<br />
            6) Do not do this when device is working fine.<br />
          </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4 mb-4" @submit.stop.prevent>
      <b-button-group class="mr-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group buttons v-model="cbktype" class="mr-4" :options="cbktypes" @change="cbkchange" button-variant="outline-primary"></b-radio-group>
      <b-input-group class="col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4">
          <b-form-input placeholder="StartID" v-model="cidstart" @keyup.enter="dofilter"></b-form-input>
          <b-form-input placeholder="EndID" v-model="cidend" @keyup.enter="dofilter"></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="dofilter">{{'btn_Filter'|trans}}</b-button>
          </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'admstores',
    mounted() {
      this.cbktype = parseInt(localStorage.getItem('admscbk')) || 0;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        workingid:-1,
        showid:0,//0=正常模式 1电表修改
        workerid: -1,
        items: [],
        target: -1,
        appseshow: false,
        modalshow: false,
        debugshow: false,
        maskenabled: false,
        modalmsg: '',
        debugmsg: '',
        cbktype: 0,
        sort: 0,
        updown:1,
        cidstart: '',
        cidend: '',
        newdevidtype: 0,
        newdeviceidbk: '',
        newdeviceidac: '',
        newdeviceiddc: '',
        DEVDATA: false,
        resetflags:[this.$t('message.ref0'),this.$t('message.ref1'),this.$t('message.ref2'),this.$t('message.ref3'),this.$t('message.ref4'),this.$t('message.ref5'),this.$t('message.ref6'),this.$t('message.ref7'),this.$t('message.ref8'),this.$t('message.ref9'),this.$t('message.refa'),this.$t('message.refb'),this.$t('message.refc'),this.$t('message.refd'),this.$t('message.refe')],
        cbktypes: [{text: this.$t('message.cbk0'),value: 0}, {text: this.$t('message.cbk1'),value: 1}, {text: this.$t('message.cbk2'),value: 2}],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      cbkchange($event) {
        this.workingid = -1;
        this.workerid = -1;
        this.cbktype = $event;
        localStorage.setItem('admscbk',''+$event);
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      fetchprev() {
        this.workingid = -1;
        this.workerid = -1;
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.workingid = -1;
        this.workerid = -1;
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.workingid = -1;
        this.workerid = -1;
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      exshowid() {
        this.workingid = -1;
        this.workerid = -1;
        this.showid = 1-this.showid;
        this.fetchData();
      },
      changesort(id) {
        if (this.sort!=id) {
          this.sort = id;
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
        }
      },
      doupdown(id) {
        if (this.updown==1) {
          this.updown = 0;
        } else {
          this.updown = 1;
        }
        if (this.sort!=id) {
          this.sort = id;
          this.updown = 1;
        }
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      dofilter() {
        this.sort = 0;
        this.pageid = 0;
        this.updown = 1;
        this.newpagestart = [];
        this.fetchData();
      },
      setme(index) {
        this.workerid = -1;
        this.workingid = index;
      },
      setwid(index) {
        this.workingid = -1;
        this.workerid = index;
      },
      async docmd(id,itm) {
        if (id==3) {
          this.modalmsg = 'Upgrade only used by factory.';
          this.modalshow = true;
        } else {
        let lotoken = localStorage.getItem('token');
        itm.connected = 0;
        let params = 'token='+lotoken+'&mac='+itm.mac+'&cmd='+id;
        let axresp = await this.axios.post('/admdocmd?tm='+new Date().getTime(), params);
        if (axresp.status==200) {
          this.fetchData();
        }
        }
      },
      selectapp(id) {
        this.newdevidtype = id;
        this.appseshow = true;
      },
      async storedelete(itm) {
        var msg = 'really want to delete device?';
        if (confirm(msg)) {
          let lotoken = localStorage.getItem('token');
          let params = 'token=' + lotoken + '&mac=' + itm.mac;
          let axresp = await this.axios.post('/admstodelete?tm=' + new Date().getTime(), params);
          if (axresp.status == 200) {
              let axdata = axresp.data;
              if (axdata.rc>0) {
                this.fetchData();
              } else {
                this.modalmsg = axdata.rm;
                this.modalshow = true;
              }
          }
        }
      },
      async fetchData() {
        this.isloading = true;
        let lotoken = localStorage.getItem('token');
        let w = window.innerWidth;
        let h = window.innerHeight;
        let params = 'token='+lotoken+'&cbk='+this.cbktype+'&pagesize='+this.$pagesize+'&sort='+this.sort+'&ud='+this.updown+'&cidst='+this.cidstart+'&ciden='+this.cidend+'&w='+w+'&h='+h;
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          params = params + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admstores?tm='+new Date().getTime(), params);
        this.isloading = false;
        if (axresp.status == 200) {
            let axdata = axresp.data;
            if (axdata.Items) this.items = axdata.Items;
            if (axdata.LastEvaluatedKey) {
              if (this.newpagestart.length<=this.pageid) {
                this.newpagestart.push(axdata.LastEvaluatedKey);
              } else {
                this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
              }
            }
        } else {
            this.items = [];
        }
      },
      closemask() {
        this.maskenabled = false;
      },
      async resetchargerid(itm) {
        this.workingid = -1;
        let mac = itm.mac;
        let cid = itm.chargerid;
        let lotoken = localStorage.getItem('token');
        let params = 'token='+lotoken+'&mac='+mac+'&cid='+cid+'&cbk='+this.cbktype;
        let axresp = await this.axios.post('/admresetcid?tm='+new Date().getTime(), params);
        if (axresp.status==200) {
          if (axresp.data.rm) {
            this.modalmsg = axresp.data.rm;
            this.modalshow = true;
          }
          this.fetchData();
        }
      },
      gotodevice(itm) {
        if (this.cbktype==1) {
          window.location.href = '/#/chargerev/' + itm.chargerid;
        } else {
          window.location.href = '/#/chargerbk/' + itm.chargerid;
        }
      },
      powverify(itm) {
        window.location.href = '/#/powverify?mac=' + itm.mac + '&cbk=' + this.cbktype + '&cid=' + itm.chargerid + '&ver=' + itm.ver;
      },
      async savepow(itm) {
        let pow0 = Number(itm.pow0);
        let pow1 = Number(itm.pow1);
        this.workingid = -1;
        this.workerid = -1;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token=' + lotoken + '&mac=' + itm.mac+'&pow0='+pow0+'&pow1='+pow1+'&cbk='+this.cbktype;
        let axresp = await this.axios.post('/admpowset?tm=' + new Date().getTime(), theapi);
        if (axresp.status == 200) {
          let axdata = axresp.data;
          if (axdata.rc>0) {
            this.fetchData();
          } else {
            this.modalmsg = axdata.rm;
            this.modalshow = true;
          }
        }
      },
    }
  }
</script>
